<template>
  <section style="width: 100%; height: 79vh">
    <iframe
      style="width: 100%; height: 100%; border: 0; background-color: white"
      src="https://docs.google.com/document/d/e/2PACX-1vT4OE8MPZJC3K4w8qejphRRG8MRLUPsM7r-98FX3j_nNVlHqQBKVgCzv052AyiUGaOVPsYiacoW4WGA/pub?embedded=true"
    />
  </section>
</template>

<script>
export default {}
</script>

<style></style>
