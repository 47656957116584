var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticStyle: { width: "100%", height: "79vh" } }, [
      _c("iframe", {
        staticStyle: {
          width: "100%",
          height: "100%",
          border: "0",
          "background-color": "white",
        },
        attrs: {
          src: "https://docs.google.com/document/d/e/2PACX-1vT4OE8MPZJC3K4w8qejphRRG8MRLUPsM7r-98FX3j_nNVlHqQBKVgCzv052AyiUGaOVPsYiacoW4WGA/pub?embedded=true",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }